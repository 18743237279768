import analytics from "../analytics";

const INTERNAL_URL_PREFIXES = ['/', 'https://shor.dev', 'http://shor.dev'];

export const logLinkClick = (pageLocation: string, link: HTMLAnchorElement, cta: string) => {
  analytics.logEvent('click', {
    content_type: pageLocation + '_link',
    event_category: INTERNAL_URL_PREFIXES.some((prefix) => link.href.startsWith(prefix)) ? 'inbound' : 'outbound',
    event_label: link.href,
    cta: cta
  });
};
