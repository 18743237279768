import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {LoggingLink, LoggingLinkProps} from './logging';

export interface IProps extends LoggingLinkProps {
  githubJupyterNotebookUrl: string;
  size?: 'small' | 'medium' | 'large';
}

const useStyles = makeStyles({
  colab: {
    margin: '0em 1em .25em',
    textTransform: 'inherit',
    verticalAlign: 'middle'
  }
});

const width = {
  'small': 125,
  'medium': 150,
  'large': 200
};

export const ColabButton: React.FC<IProps> = ({githubJupyterNotebookUrl, logProps, size}) => {
  const buttonWidth = size ? width[size] : width.medium;
  const jupyterNotebookUrl = new URL(githubJupyterNotebookUrl);
  const classes = useStyles();
  const cta = 'Open in Colab';

  return <LoggingLink className={classes.colab}
                      logProps={{...logProps, cta}}
                      href={`https://colab.research.google.com/github/${jupyterNotebookUrl.pathname}`}
                      rel="noopener noreferrer"
                      target="_blank">
    <img width={buttonWidth} src="https://colab.research.google.com/assets/colab-badge.svg" alt="Open in Colab"/>
  </LoggingLink>;
};
