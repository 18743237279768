import React from 'react';

import {Box, Card, Grid, Link, Paper, Typography} from '@material-ui/core';
import {FullPage} from './templates/FullPage';
import {Hero} from "../components/Hero";
import {Logo} from "../components/Logo";
import {MuiMailchimpForm} from "../components/MuiMailchimpForm";
import {CodeSnippet} from "../components/CodeSnippet";
import {MaxWidthBox} from "../components/MaxWidthBox";
import {ColabButton} from "../components/ColabButton";
import {LoggingButton} from "../components/logging";

export function Homepage() {
  return <FullPage title={'Shor: Python quantum computing API'}>
    <Hero>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={11} >
            <Typography align="center" color="textSecondary" gutterBottom={true} variant="h1" ><Logo/>shor</Typography>
            <Typography align="center" color="textSecondary" gutterBottom={true} variant="h4" variantMapping={{'h4': 'h2'}}>
              The Python <Link underline='hover' href="https://pypi.org/project/shor/" >Quantum Computing Library</Link> for Humans.</Typography>
        </Grid>

        <Grid item xs={11} >
            <Typography color="textSecondary" align="center"  variant="h5" variantMapping={{'h5': 'h3'}}>
              in early development and looking for contributors. Contact us at <Link underline='hover'
                                                                    color='secondary'
                                                                    href="mailto:shordotdev@gmail.com"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
            >
              shordotdev@gmail.com
            </Link>
            </Typography>
        </Grid>
        <Grid item xs={11} sm={10} md={5} lg={3}>
          <MuiMailchimpForm
            action='https://dev.us4.list-manage.com/subscribe/post?u=87d625eaa368d1a131e8ffaac&amp;id=15a59e8912'
          />
        </Grid>
      </Grid>
    </Hero>
    <Paper id={'QuickStart'} square={true}>
      <MaxWidthBox px={3} py={8}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={6}
        >
          <Grid item xs={11}>
            <Typography gutterBottom={true} variant="h2">Quick Start</Typography>
            <Typography component="p" variant="h5" paragraph={true}>Code with shor directly from your Google Drive.</Typography>
            <Card raised={true}>
              <Box p={3}>
                <Typography variant="subtitle1">
                  <ul>
                    <li>01_superposition_and_entanglement.ipynb <ColabButton githubJupyterNotebookUrl="https://github.com/shor-team/shor/blob/master/tutorials/intro/01_superposition_and_entanglement.ipynb" logProps={{pageLocation:"quickstart"}} /></li>
                  </ul>
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={11}>
            <Grid item>
              <Typography gutterBottom={true} variant="h3">How to use shor</Typography>
            </Grid>
            <Grid item>
              <Typography component="p" variant="h5">
                Install the library using pip
                <LoggingButton
                  color="primary"
                  logProps={{pageLocation:"quickstart"}}
                  href="https://pypi.org/project/shor/"
                  size="small"
                  variant="contained"
                 >
                  View shor on PYPI
                </LoggingButton>
              </Typography>
              <CodeSnippet>{`pip install shor`}</CodeSnippet>
            </Grid>
            <Grid item>
              <Typography component="p" variant="h5">
                Import the modules
              </Typography>
              <CodeSnippet>
                {`from shor.quantum import Circuit
from shor.layers import Qbits
from shor.gates import CNOT, Hadamard
from shor.operations import Measure`}
              </CodeSnippet>
              </Grid>
              <Grid item>
              <Typography component="p" variant="h5">
                Build a quantum circuit
              </Typography>
              <CodeSnippet>
                {`circuit = Circuit()
circuit.add(Qbits(1))
circuit.add(Hadamard(0))  # Can also use H()
circuit.add(Measure(0))`}
              </CodeSnippet>
              </Grid>
              <Grid item>
              <Typography component="p" variant="h5">
                Draw the circuit
              </Typography>
              <CodeSnippet>
                {`circuit.draw()`}
              </CodeSnippet>
            </Grid>
              <Grid item>
              <Typography component="p" variant="h5">
                Run the circuit 100 times
              </Typography>
              <CodeSnippet>
                {`result = circuit.run(100)`}
              </CodeSnippet>
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Typography gutterBottom={true} variant="h3">Congratulations!</Typography>
            <Typography component="p" variant="h5">
              You just ran a quantum program. This program simulates a coin flip with quantum mechanics, using superposition to randomly measure 0 or 1 from a qbit.
            </Typography>
          </Grid>
        </Grid>
      </MaxWidthBox>
    </Paper>
  </FullPage>;
}
