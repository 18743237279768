import React, {FormEvent} from 'react';
import jsonp from 'jsonp';
import {Alert} from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import analytics from "../analytics";

const FORM_FIELDS = ['EMAIL', 'FNAME'];
const CALL_TO_ACTION = 'Get project updates';

export  interface IProps {
  action: string;
}

interface IState {
  formData: Record<string, string>;
  submitted: boolean;
  status?: Status;
}
enum Status {
  DUPLICATE = 'duplicate',
  ERROR = 'error',
  SENDING = 'sending',
  SUCCESS = 'success'
}

export class MuiMailchimpForm extends React.Component<IProps, IState> {
  state: IState = {
    formData: {
    },
    submitted: false,
  };

  constructor(props: IProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendData = this.sendData.bind(this);
  }

  handleChange = (event: FormEvent) => {
    const formElement: HTMLInputElement = event.target as HTMLInputElement;
    const { formData } = this.state;
    formData[formElement.name] = formElement.value;
    this.setState({ formData });
  };

  sendData(url: string) {
    this.setState({ status: Status.SENDING });
    jsonp(url, { param: "c" }, (err: Error | null, data: {msg: string, result: string}) => {
      if (data.msg.includes("already subscribed")) {
        this.setState({ status: Status.DUPLICATE });
      } else if (err) {
        this.setState({ status: Status.ERROR });
      } else if (data.result !== Status.SUCCESS) {
        this.setState({ status: Status.ERROR });
      } else {
        analytics.logEvent('sign_up', { 'method': 'Newsletter', 'call_to_action': CALL_TO_ACTION});
        this.setState({ status: Status.SUCCESS });
      }
    });
  }

  handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    // @ts-ignore
    this.setState({submitted:true}, () => {
      const values = FORM_FIELDS.map(field => {
        return `${field}=${encodeURIComponent(this.state.formData[field])}`;
      }).join("&");
      const path = `${this.props.action}&${values}`;
      const url = path.replace('/post?', '/post-json?');
      this.sendData(url);
    });
  };

  render() {
    const { formData, submitted } = this.state;
    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <TextValidator
          label="Name"
          onChange={this.handleChange}
          name="FNAME"
          margin="normal"
          fullWidth={true}
          variant="outlined"
          value={formData.FNAME}
          validators={['required']}
          errorMessages={['this field is required']}
        />
        <TextValidator
          label="Email"
          fullWidth={true}
          onChange={this.handleChange}
          name="EMAIL"
          margin="normal"
          variant="outlined"
          value={formData.EMAIL}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'email is not valid']}
        />
        <br />
        {
          this.state.status === Status.DUPLICATE &&
          <Alert severity="info">There is already a subscription for that email</Alert>
        }
        {
          this.state.status === Status.ERROR &&
          <Alert severity="error">Something went wrong with that email</Alert>
        }
        {
          this.state.status === Status.SUCCESS &&
          <Alert severity="success">You are now subscribed!</Alert>
        }
        <br/>
        <Button
          color="primary"
          fullWidth={true}
          size="large"
          variant="contained"
          type="submit"
          disabled={submitted}
        >
          {CALL_TO_ACTION}
        </Button>
      </ValidatorForm>
    );
  }
}
