import React from 'react';
import shorlogo from "../img/shorlogo.png";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: '.2em',
    maxHeight: 'calc(1em + .5rem)',
    verticalAlign: 'middle'
  }
}));

export function Logo() {
  const classes = useStyles();

  return <img src={shorlogo} alt="logo" className={classes.logo} />;
}
