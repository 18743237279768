import React from 'react';
import {Box, BoxProps, Hidden, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Logo} from "./Logo";
import GetApp from '@material-ui/icons/GetApp';
import analytics from "../analytics";
import {LoggingButton, LoggingLink} from "./logging";
import {GitHub} from "@material-ui/icons";
import {AppBar} from "./theme";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(.5),
    padding: theme.spacing(2)
  },
}));

const PaddedIcon: React.FC<BoxProps & {icon?: React.ElementType}> = (props) => <Box component={props.icon || props.component} {...props} sx={{fontSize: 'inherit', minWidth: '25px', mx: .5, ...props.sx}} />;

export const Navigation: React.FC = () => {
  const classes = useStyles();

  const pageLocation = "nav";

  return <AppBar>
    <Toolbar>
      <LoggingLink logProps={{pageLocation: "nav"}} underline="none" variant="h5" color="textPrimary" href="https://shor.dev" noWrap className={classes.toolbarTitle}>
        <Logo/><Hidden xsDown>shor<Hidden smDown>.dev</Hidden></Hidden>
      </LoggingLink>
      <nav>
        <LoggingButton href="#QuickStart" className={classes.link} logProps={{pageLocation}}>
          Quick Start
        </LoggingButton>
        <LoggingButton href="/api" className={classes.link} logProps={{pageLocation}}>
          API Docs
        </LoggingButton>
        <LoggingButton href="https://pypi.org/project/shor/" target="_blank" rel="nofollow" logProps={{pageLocation}} className={classes.link} onClick={() => {
            analytics.logEvent('click', {content_type: 'nav_dropdown_link', event_category: 'outbound', event_label: 'https://pypi.org/project/shor/', dropdown_text: 'Install', cta: 'PYPI Package'});
          }}>
          Install <PaddedIcon icon={GetApp} />
        </LoggingButton>
        <LoggingButton href="https://github.com/shor-team/shor" target="_blank" rel="nofollow" logProps={{pageLocation}} className={classes.link} onClick={() => {
          analytics.logEvent('click', {content_type: 'nav_dropdown_link', event_category: 'outbound', event_label: 'https://github.com/shor-team/shor', dropdown_text: 'Install', cta: 'Github - Open Source'});
        }}>
          GitHub <PaddedIcon icon={GitHub} />
        </LoggingButton>
      </nav>
    </Toolbar>
  </AppBar>;
};

