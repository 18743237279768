import React from 'react';
import './App.scss';
import 'fontsource-barlow';
import {Homepage} from "./pages/Homepage";
import {Navigation} from './components/Navigation';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {quantumTheme} from "./themes/quantum";
import {ParticleWave} from "./components/ParticleWave";
import {teal} from "@material-ui/core/colors";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={quantumTheme}>
          <CssBaseline>
            <Navigation />
            <ParticleWave scaleY={1} particleColor={teal[900]}/>
            <Homepage/>
          </CssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default App;
