import * as MUI from "@material-ui/core";
import React from "react";


export const AppBar: React.FC<MUI.AppBarProps & MUI.BoxProps> = (props) => <MUI.Box
  component={MUI.AppBar} color="transparent" position="sticky" {...props}
  sx={{
      background: (theme) => theme.palette.background.paper,
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      a: { color: 'text.primary'},
      left: 0,
      top: 0,
      zIndex: 1,
      ...props.sx
}} />;


