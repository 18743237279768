import {grey, red} from "@material-ui/core/colors";

export const BASE_THEME_OPTIONS = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 700,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    "fontFamily": `"Barlow", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 500,
    "fontWeightRegular": 500,
    "fontWeightMedium": 600
  },
  palette: {
    primary: {
      main: grey[100]
    },
    secondary: {
      main: red['A700'],
    },
    text: {
      secondary: grey[50]
    }
  },
};
