import React, {Component} from "react";
import * as THREE from 'three';
import './ParticleWave.scss';
import {Fade} from "@material-ui/core";

const SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;

export interface IProps {
  scaleX?: number;
  scaleY?: number;
  particleColor?: number | string;
}

export class ParticleWave extends Component<IProps> {
  private camera?: THREE.PerspectiveCamera;
  private scene?: THREE.Scene;
  private particles?: THREE.Points<THREE.BufferGeometry, THREE.ShaderMaterial>;
  private renderer?: THREE.WebGLRenderer;
  private count: number = 0;
  private mouseX: number = 0;
  private mouseY: number = 0;
  private windowHalfX: number = 200;
  private windowHalfY: number = 50;
  private canvas?: HTMLCanvasElement;
  private scaleX: number;
  private scaleY: number;
  private particleColor: number;

  constructor(props: IProps){
    super(props);
    this.scaleX = props.scaleX || 1;
    this.scaleY = props.scaleY || 1;
    this.particleColor = props.particleColor ? parseInt(props.particleColor.toString().replace(/^#/, ''), 16) : 0xffffff;
    this.onWindowResize = this.onWindowResize.bind(this);
    this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
    this.onDocumentTouchStart = this.onDocumentTouchStart.bind(this);
    this.onDocumentTouchMove = this.onDocumentTouchMove.bind(this);
  }

  onWindowResize() {
    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;

    if (this.camera) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
    }

    if (this.renderer) {
      this.renderer.setSize(this.scaleX * window.innerWidth, this.scaleY * window.innerHeight);
    }
  }

  onDocumentMouseMove( event: MouseEvent) {
    this.mouseX = event.clientX - this.windowHalfX;
    this.mouseY = event.clientY - this.windowHalfY;
  }

  onDocumentTouchStart( event: TouchEvent ) {
    if ( event.touches.length === 1 ) {
      event.preventDefault();

      this.mouseX = event.touches[ 0 ].pageX - this.windowHalfX;
      this.mouseY = event.touches[ 0 ].pageY - this.windowHalfY;
    }
  }

  onDocumentTouchMove( event: TouchEvent ) {
    if ( event.touches.length === 1 ) {
      event.preventDefault();

      this.mouseX = event.touches[ 0 ].pageX - this.windowHalfX;
      this.mouseY = event.touches[ 0 ].pageY - this.windowHalfY;
    }
  }

  addEventListeners() {
    document.addEventListener( 'mousemove', this.onDocumentMouseMove, false );
    document.addEventListener( 'touchstart', this.onDocumentTouchStart, false );
    document.addEventListener( 'touchmove', this.onDocumentTouchMove, false );

    window.addEventListener( 'resize', this.onWindowResize, false );
  }

  removeEventListeners() {
    document.removeEventListener( 'mousemove', this.onDocumentMouseMove, false );
    document.removeEventListener( 'touchstart', this.onDocumentTouchStart, false );
    document.removeEventListener( 'touchmove', this.onDocumentTouchMove, false );

    window.removeEventListener( 'resize', this.onWindowResize, false );
  }

  componentDidMount() {
    this.sceneSetup();
    this.addCustomSceneObjects();
    this.addEventListeners();
    this.startAnimationLoop();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  sceneSetup = () => {
    this.count = 0;
    this.mouseX = 0;
    this.mouseY = 0;

    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;

    this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 10000 );
    this.camera.position.z = 1200;

    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer( { alpha: true, antialias: true, canvas: this.canvas } );
    // this.renderer.setPixelRatio( 2 );
    this.renderer.setSize( this.scaleX * window.innerWidth, this.scaleY * window.innerHeight );
    this.renderer.setClearColor( 0x000000, 0 ); // the default
  };

  addCustomSceneObjects  = () => {
    const numParticles = AMOUNTX * AMOUNTY;

    const positions = new Float32Array(numParticles * 3);
    const scales = new Float32Array(numParticles);

    let i = 0, j = 0;

    for (let ix = 0; ix < AMOUNTX; ix ++ ) {
      for (let iy = 0; iy < AMOUNTY; iy ++ ) {
        positions[ i ] = ix * SEPARATION - ( ( AMOUNTX * SEPARATION ) / 2 ); // x
        positions[ i + 1 ] = 0; // y
        positions[ i + 2 ] = iy * SEPARATION - ( ( AMOUNTY * SEPARATION ) / 2 ); // z

        scales[ j ] = 1;

        i += 3;
        j ++;
      }
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
    geometry.setAttribute( 'scale', new THREE.BufferAttribute( scales, 1 ) );

    const material = new THREE.ShaderMaterial( {
      uniforms: {
        color: { value: new THREE.Color( this.particleColor) }

      },
      vertexShader: `
                attribute float scale;
    
                void main() {
    
                    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    
                    gl_PointSize = scale * ( 300.0 / - mvPosition.z );
    
                    gl_Position = projectionMatrix * mvPosition;
    
                }
			`,
      fragmentShader: `
                uniform vec3 color;
    
                void main() {
    
                    if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
    
                    gl_FragColor = vec4( color, 1.0 );
    
                }
			`
    } );

    this.particles = new THREE.Points( geometry, material );
    this.scene?.add(this.particles);
  };

  startAnimationLoop = () => {
    window.requestAnimationFrame(this.startAnimationLoop);

    if (this.particles) {
      let positions = this.particles.geometry.attributes.position.array;
      let scales = this.particles.geometry.attributes.scale.array;

      let i = 0, j = 0;

      for (let ix = 0; ix < AMOUNTX; ix++) {

        for (let iy = 0; iy < AMOUNTY; iy++) {

          // @ts-ignore
          positions[i + 1] = (Math.sin((ix + this.count) * 0.3) * 50) +
            (Math.sin((iy + this.count) * 0.5) * 50);

          // @ts-ignore
          scales[j] = 10 + (Math.sin((ix + this.count) * 0.3) + 1) * 8 +
            (Math.sin((iy + this.count) * 0.5) + 1) * 8;

          i += 3;
          j++;

        }

      }

      // @ts-ignore
      this.particles.geometry.attributes.position.needsUpdate = true;
      // @ts-ignore
      this.particles.geometry.attributes.scale.needsUpdate = true;
    }

    if (this.camera && this.scene) {
      this.camera.position.x += (.5*this.mouseX - this.camera.position.x) * .01;
      this.camera.position.y += (550 -.5*this.mouseY - this.camera.position.y) * .01;
      this.camera.lookAt(this.scene.position);

      if (this.renderer) {
        this.renderer.render(this.scene, this.camera);

        this.count += 0.05;
        if (this.renderer && this.scene && this.camera) {
          this.renderer.render(this.scene, this.camera);
        }
      }
    }
  };


  render() {
    return <div className="particles" style={{height: `${this.scaleY*100}vh`, width: `${this.scaleX*100}vw`, marginBottom: `-${this.scaleY*100}vh`}}>
      <Fade in={true} timeout={2000}>
        <canvas ref={(ref) => {
          if (ref) {
            this.canvas = ref;
          }
        }} />
      </Fade>
    </div>;
  }
}
