import React from "react";
import {Button, ButtonProps, Link, LinkProps} from "@material-ui/core";
import {logLinkClick} from "../logging/utils";

export interface LogProps{
  cta?: string;
  pageLocation: string;
}

export interface LoggingLinkProps extends LinkProps {
  logProps: LogProps;
}

export const LoggingLink: React.FC<LoggingLinkProps> = ({
                                                          children,
                                                          logProps: {
                                                            cta,
                                                            pageLocation
                                                          },
                                                          ...otherProps
                                                        }) => {
  return <Link {...otherProps} component="a" onClick={(e: any) => {
    // @ts-ignore
    const link: HTMLAnchorElement = e.currentTarget as HTMLAnchorElement;
    logLinkClick(pageLocation, link, cta ? cta : `${children}`);
  }}>{children}</Link>
};


export interface LoggingButtonProps extends ButtonProps {
  logProps: LogProps;
  target?: string;
  rel?: string;
}

export const LoggingButton: React.FC<LoggingButtonProps> = (props) => {
  // @ts-ignore
  return <Button component={LoggingLink} {...props}/>;
};

