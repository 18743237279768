import React from "react";

import Box, {BoxProps} from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  maxWidth: {
    maxWidth: '1240px',
    margin: '0 auto'
  }
});

export const MaxWidthBox: React.FC<BoxProps> = (props) => {
  const classes = useStyles();
  return <Box className={classes.maxWidth} {...props} />
};
