import React from 'react';
import {Box, Fade} from "@material-ui/core";

export interface IProps {
  scaleY?: number
}

export const DEFAULT_HERO_Y_SCALE = .9; // 1 = 100vh, .5 = 50vh

export const Hero: React.FC<IProps> = ({children, scaleY}) => {
  return <Fade in={true} timeout={800}>
      <Box px={3} py={6} className="hero" style={{
      background: 'rgba(0,0,0,.2)',
      minHeight: `calc(${(scaleY ? scaleY : DEFAULT_HERO_Y_SCALE)*100}vh - 65px)`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "safe center",
      overflow: 'hidden'
    }}>
      {children}
    </Box>
  </Fade>;
};
