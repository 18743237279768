import React from 'react';
import {Box} from "@material-ui/core";
import Prism from 'prismjs';
import "prismjs/components/prism-python";
import "prismjs/themes/prism-okaidia.css";

export interface IProps {

}

export const CodeSnippet: React.FC<IProps> = ({children}) => {
  React.useEffect(() => {
    Prism.highlightAll();
  });

  return <Box pb={2}>
    <pre>
      <code className="language-python">
        {children}
      </code>
    </pre>
  </Box>;
};
