import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD2jy2v-CNb8s_APsg_LW4x8MsbL1qoq7k",
  authDomain: "shor-firebase.firebaseapp.com",
  databaseURL: "https://shor-firebase.firebaseio.com",
  projectId: "shor-firebase",
  storageBucket: "shor-firebase.appspot.com",
  messagingSenderId: "485340112198",
  appId: "1:485340112198:web:b3742c71db5f46f188b147",
  measurementId: "G-ZG71Q8LSSH"
};

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

export default analytics;
