import {createMuiTheme} from "@material-ui/core/styles";
import {BASE_THEME_OPTIONS} from "./base";
import {amber, cyan, grey, red} from "@material-ui/core/colors";

export const quantumTheme = createMuiTheme({
  ...BASE_THEME_OPTIONS,
  palette: {
    primary: {
      main: amber[500]
    },
    secondary: {
      main: cyan[300]
    },
    text: {
      secondary: grey[50]
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active': {
            '-webkit-transition': 'color 9999s ease-out, background-color 9999s ease-out',
            '-webkit-transition-delay': '9999s',
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1em',
          '&.Mui-error': {}
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: grey[50]
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          borderWidth: 0,
          '&$shrink': {
            transform: 'translate(14px, -6px) scale(.85)'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: grey[50],
          '&:hover': {
            backgroundColor: 'rgba(20, 20, 20, .8)',
            color: grey[50],
            "& .MuiOutlinedInput-notchedOutline": {
              border: '2px solid ' + amber[500],
            },
          },
          '&.Mui-focused, &.Mui-error': {
            backgroundColor: 'rgba(20, 20, 20, .8)',
            color: grey[50]
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid ' + amber[500],
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            border: '2px solid ' + red[500],
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontSize: '.75em',
          margin: '0em 1em .25em',
          textTransform: 'inherit',
          verticalAlign: 'middle'
        },
        containedSizeLarge: {
          padding: '1em',
          fontSize: '1.5em',
          margin: 0,
          border: '3px solid ' + amber[500],
          '&:hover': {
            color: grey[50]
          }
        }
      }
    }
  }
});
