import React from 'react';
import { Helmet } from 'react-helmet';

export interface FullPageProps {
    title: string;
}

export const FullPage: React.FC<FullPageProps> = ({children, title}) => {
    return <>
        <Helmet>
            <title>{ title || 'Shor: Python quantum computing API' }</title>
        </Helmet>
        {children}
    </>
};
